import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Registration } from '../landing/landing.model';
import { LandingService } from '../landing/landing.service';
import { SnackbarService } from '../shared/notifications/snackbar.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  // Set variables
  isLoading = false;
  entity?: string;
  name?: string;
  email?: string;

  constructor(private formBuilder: FormBuilder, public landingService: LandingService, private activatedRoute: ActivatedRoute, public router: Router, public snackbarService: SnackbarService) { }

  // Form details
  registrationDetails: Registration = {
    name: '',
    email: '',
    countryCode: '',
    mobile: '',
    entity: ''
  }

  // Form builder
  registrationForm = this.formBuilder.group({
    name: [null, Validators.required],
    email: [null, [Validators.required, Validators.email]],
    mobile: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
    entity: [null, Validators.required]
  });

  ngOnInit(): void {
    // Get params id
    this.activatedRoute.params.subscribe((params) => {
      this.entity = params['entity'];
      this.name = params['name'];
      this.email = params['email'];

      if (this.entity) {
        this.registrationControls.entity.setValue(this.entity);
      }
      if (this.name && this.email) {
        this.registrationControls.name.setValue(this.name);
        this.registrationControls.email.setValue(this.email);
      }
    });
  }

  // Form getter
  get registrationControls() {
    return this.registrationForm.controls;
  }

  // On form submit
  onSubmit() {
    if (this.registrationForm.valid) {
      this.isLoading = true;
      this.registrationDetails = {
        name: this.registrationControls.name.value,
        email: this.registrationControls.email.value,
        countryCode: '+91',
        mobile: this.registrationControls.mobile.value,
        entity: this.registrationControls.entity.value
      }
      this.landingService.preRegistration(this.registrationDetails).subscribe(resultData => {
        this.isLoading = false;
        this.registrationForm.reset();
        this.router.navigate(['/registration']);
        setTimeout(() => {
          this.snackbarService.success('Your pre registration request submitted successfully!', 'Dismiss', 'top', 6000);
        }, 600);
      }, (error: any) => {
        this.isLoading = false;
        console.log(error);
      })
    }
  }

  // Number validation 
  onKeypress(event: any) {
    const pattern = /[0-9\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

}
