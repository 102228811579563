import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Newsletter, Registration } from './landing.model';

const API_URL_NEWSLETTER = environment.apiBaseUrl + "/newsletters";
const API_URL_REGISTRATIONS = environment.apiBaseUrl + "/registrations";
const HEADERS = {
  headers: new HttpHeaders({
    'Authorization': 'Bearer ' + environment.webToken,
    'Username': environment.webUser
  })
}

@Injectable({
  providedIn: 'root'
})
export class LandingService {

  constructor(private http: HttpClient) { }

  // Subscribe newsletter
  subscribeNewsletter(newsletterData: Newsletter) {
    return this.http.post<{ message: string }>(API_URL_NEWSLETTER + '/subscribe', newsletterData, HEADERS);
  }

  // Pre registration
  preRegistration(registrationData: Registration) {
    return this.http.post<{ message: string }>(API_URL_REGISTRATIONS, registrationData, HEADERS);
  }

}
