import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { SnackbarService } from '../notifications/snackbar.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {

    constructor(private snackbarService: SnackbarService, private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                // App side error
                if (error.error instanceof ErrorEvent) {
                    // display error message 
                    this.snackbarService.error(error.error.message, 'Dismiss', 'top', 4000);
                }
                // Server side error
                else {
                    if (error.status === 500) {
                        //  redirect to error page 
                        this.snackbarService.error('Internal server error!', 'Dismiss', 'top', 4000);
                    } else {
                        // display error message
                        this.snackbarService.error(error.error.message, 'Dismiss', 'top', 4000);
                    }
                }
                // Server stopped error
                if (error.status === 0) {
                    this.snackbarService.error('Somthing went wrong!', 'Dismiss', 'top', 4000);
                }
                return throwError(error.message);
            })
        );
    }
}