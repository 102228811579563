// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://beta.upsidon.com/api',
  // apiBaseUrl: 'http://localhost:4000/api',
  studentWebsiteUrl: 'https://beta.upsidon.com/student',
  teacherWebsiteUrl: 'https://beta.upsidon.com/teacher',
  instituteWebsiteUrl: 'https://beta.upsidon.com/institute',
  webToken: 'AZeIOGxwpe48pT2MTP5vaHhuXpYUksZa',
  webUser: 'Upsidon-Website',
  version: '1.0.0'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
