<div class="registration registration-background-image" ngClass.xs="registration-background-image-responsive"
  ngClass.sm="registration-background-image-responsive" ngClass.md="registration-background-image-responsive">
  <div class="section" ngClass.xs="mobile" ngClass.sm="mobile" ngClass.md="tablet" ngClass.lg="desktop"
    ngClass.xl="desktop">
    <div fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="row" fxLayout.lg="row" fxLayout.xl="row"
      fxLayoutGap="106px" fxLayoutGap.xs="0px" fxLayoutGap.sm="0px" fxLayoutAlign="start stretch">
      <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">
        <div class="registration-left" [ngStyle.xs]="{'padding-top.px': 26}" [ngStyle.sm]="{'padding-top.px': 26}">
          <div class="mat-display-2">Welcome to <span>Upsidon</span></div>
          <h3>Pre-Register Here and get 4 Months of Unlimited Access to the Beta Version.</h3>
          <div class="side-image">
            <img src="assets/images/registration-side-image.png" alt="Registration">
          </div>
        </div>
      </div>
      <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">
        <div class="registration-right" [ngStyle.xs]="{'padding-top.px': 0}" [ngStyle.sm]="{'padding-top.px': 0}">
          <div class="registration-form">
            <div class="logo">
              <img class="logo-text" src="assets/images/logo-full.svg" alt="Upsidon logo">
            </div>
            <p>Fill this form and we will let you know when the portal is ready
              for your service </p>

            <form [formGroup]="registrationForm" [ngStyle.xs]="{'padding-bottom.px': 26}"
              [ngStyle.sm]="{'padding-bottom.px': 26}">

              <mat-form-field class="round-form-field" floatLabel="auto" hideRequiredMarker appearance="outline"
                color="accent">
                <mat-label>Full name</mat-label>
                <input matInput type="text" formControlName="name" autocomplete="off" required>
                <mat-error *ngIf="registrationControls.name.hasError('required')">
                  Full name is <strong>required</strong>
                </mat-error>
              </mat-form-field>

              <div fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="row" fxLayout.lg="row" fxLayout.xl="row"
                fxLayoutGap="16px" fxLayoutGap.xs="0px" fxLayoutGap.sm="0px" fxLayoutAlign="start stretch">
                <div fxFlex="50%">
                  <mat-form-field class="round-form-field" floatLabel="auto" hideRequiredMarker appearance="outline"
                    color="accent">
                    <mat-label>Email</mat-label>
                    <input matInput type="text" formControlName="email" autocomplete="off" required>
                    <mat-error *ngIf="registrationControls.email.hasError('required')">
                      Email is <strong>required</strong>
                    </mat-error>
                    <mat-error
                      *ngIf="registrationControls.email.hasError('email') && !registrationControls.email.hasError('required')">
                      Please enter a valid email address
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex="50%">
                  <mat-form-field class="round-form-field" floatLabel="auto" hideRequiredMarker appearance="outline"
                    color="accent">
                    <mat-label>Mobile</mat-label>
                    <input matInput type="text" formControlName="mobile" minlength="10" maxlength="10"
                      (keypress)="onKeypress($event)" autocomplete="off" required>
                    <mat-error *ngIf="registrationControls.mobile.hasError('required')">
                      Mobile number is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="registrationControls.mobile.hasError('minlength')">
                      Mobile number must be <strong>10 digits</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="radio-field">
                <h5>Who you are</h5>
                <mat-radio-group formControlName="entity" required>
                  <mat-radio-button color="primary" value="institute">Institute</mat-radio-button>
                  <mat-radio-button color="primary" value="teacher">Teacher</mat-radio-button>
                </mat-radio-group>
              </div>

              <div class="form-buttons">
                <button mat-flat-button class="load-button" type="button" [routerLink]="['/']">Cancel</button>
                <button mat-flat-button type="button" class="load-button" color="accent" [disabled]="isLoading"
                  (click)="onSubmit()">
                  Submit <mat-icon *ngIf="!isLoading">chevron_right</mat-icon>
                  <mat-spinner *ngIf="isLoading" diameter="16" strokeWidth="2" mode="indeterminate" color="accent">
                  </mat-spinner>
                </button>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>